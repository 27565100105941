import React, { useEffect, useState } from "react";
import { MDBCol, MDBInput, MDBRow } from "mdb-react-ui-kit";
import * as Yup from "yup";
import { useFormik } from "formik";
import { FormModalWrapper } from "../../../components";
import {
  showErrorToast,
  getPublisher,
  updatePublisher,
  showSuccessToast,
} from "../../../helpers";

const publisherValidationSchema = Yup.object({
  name: Yup.string().required("Publisher name is required"),
});

const EditPublisherModal = ({ onClose, itemId, onSuccessSubmit }) => {
  const isOpen = !!itemId;

  const [isLoading, setIsLoading] = useState(false);

  const handleCloseModal = () => {
    onClose();
    formik.resetForm();
  };

  const formik = useFormik({
    validationSchema: publisherValidationSchema,
    initialValues: null,
    onSubmit: async (values) => {
      try {
        await updatePublisher(itemId, {
          name: values.name,
        });
        handleCloseModal();
        showSuccessToast();
        await onSuccessSubmit();
      } catch (error) {
        showErrorToast();
      }
    },
  });

  useEffect(() => {
    const fetchPublisher = async () => {
      try {
        setIsLoading(true);
        const response = await getPublisher(itemId);
        formik.setValues({
          name: response.data.name,
        });
      } catch (error) {
        showErrorToast();
        handleCloseModal();
      } finally {
        setIsLoading(false);
      }
    };

    if (!!itemId) {
      fetchPublisher();
    }
  }, [itemId]);

  return (
    <FormModalWrapper
      title={"Edit publisher"}
      isOpen={isOpen}
      isLoading={isLoading || formik.isSubmitting}
      onSubmit={formik.handleSubmit}
      onClose={handleCloseModal}
    >
      <MDBRow className="mb-3">
        <MDBCol md={6}>
          <MDBInput id="name" label="Name" {...formik.getFieldProps("name")} />
          {formik.errors.name ? (
            <div className="text-danger">{formik.errors.name}</div>
          ) : null}
        </MDBCol>
      </MDBRow>
    </FormModalWrapper>
  );
};

export default EditPublisherModal;
