import React, { useEffect } from 'react';
import { MDBContainer, MDBBtn } from 'mdb-react-ui-kit';
import { withFormik, Field } from 'formik';
import TextInput from './TextInput';
import Loader from './Loader';
import { authorize } from '../helpers/auth';
import { showErrorToast } from '../helpers/helpers';
import './LoginForm.css';

const mapPropsToValues = () => ({
    username: '',
    password: '',
});

const handleSubmit = async (value, { setStatus }) => {
    const username = value.username.trim().toLowerCase();
    authorize({
        ...value,
        username
    })
        .then(response => {
            setStatus({
                data: {
                    username,
                    mfa: response.data.nextstep === 'SOFTWARE_TOKEN_MFA' ? true : false,
                    ...response.data
                }
            })
        })
        .catch(error => {
            showErrorToast(error)
        });
};

const LoginForm = ({ setAuthResponse, setTab, handleSubmit, isSubmitting, status }) => {
    useEffect(() => {
        if (status) {
            setAuthResponse(status.data);
            setTab('qr');
        }
    }, [status, setAuthResponse, setTab]);

    return (
        <div className='main-section'>
            <MDBContainer className='form_wrapper d-flex justify-content-center'>
                {isSubmitting
                    ? <Loader />
                    : <form className='col-10 col-md-4' onSubmit={handleSubmit}>
                        <h4 className='text-center mb-3 font-weight-bolder'>Log in</h4>
                        <Field component={TextInput} name='username' label='Username' type='text' />
                        <Field component={TextInput} name='password' label='Password' type='password' />
                        <div className='text-center mt-4'>
                            <MDBBtn color='primary' type='submit'>Login</MDBBtn>
                        </div>
                    </form>
                }
            </MDBContainer>
        </div >
    );
};

export default withFormik({ handleSubmit, mapPropsToValues })(LoginForm);
