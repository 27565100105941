import React, { useEffect } from "react";
import {
  MDBCol,
  MDBRow,
  MDBTypography,
  MDBSwitch,
  MDBInput,
} from "mdb-react-ui-kit";
import Select from "react-select";
import useKeys from "../../keys/hooks/useKeys";

const PublisherProfileForm = ({ values, onChange, errors }) => {
  const { keysData } = useKeys({ itemsPerPage: Number.MAX_SAFE_INTEGER });

  const resetPubPeerAPIKey = () => onChange("service_keys.pubPeerAPIKey", null);
  const resetClearSkiesAPIKey = () =>
    onChange("service_keys.clearSkiesAPIKey", null);

  useEffect(() => {
    const handleReset = () => {
      if (values?.components?.FOCRat === false) {
        resetPubPeerAPIKey();
      }

      if (values?.components?.CSStat === false) {
        resetClearSkiesAPIKey();
      }
    };

    handleReset();
  }, [values?.components?.FOCRat, values?.components?.CSStat]);

  const keyOptions = keysData?.map((key) => ({
    value: key.id,
    label: key.key_name,
  }));

  return (
    <>
      <MDBRow className="mb-4">
        <MDBCol md={6}>
          <MDBTypography variant="h5" className="mb-3">
            Profile
          </MDBTypography>
          <MDBInput
            label="Profile name"
            id="profile_name"
            name="profile_name"
            type="text"
            onChange={({ target }) => onChange(target.name, target.value)}
            value={values?.profile_name}
          />
          {errors?.profile_name && (
            <div className="text-danger">{errors.profile_name}</div>
          )}
        </MDBCol>
        <MDBCol>
          <MDBTypography variant="h5" className="mb-3">
            Applications
          </MDBTypography>
          <MDBSwitch
            checked={values?.components?.PMChk ?? false}
            label="On-Demand Screening"
            name="components.PMChk"
            onChange={({ target }) => onChange(target?.name, target?.checked)}
          />
          <br />
          <MDBSwitch
            checked={values?.components?.AMBScr ?? false}
            label="Ambient Screening"
            name="components.AMBScr"
            onChange={({ target }) => onChange(target?.name, target?.checked)}
          />
          <br />
          <MDBSwitch
            checked={values?.components?.WTLst ?? false}
            label="Watchlist"
            name="components.WTLst"
            onChange={({ target }) => onChange(target?.name, target?.checked)}
          />
        </MDBCol>
      </MDBRow>
      <MDBTypography variant="h5" className="mb-3">
        Tools & signals
      </MDBTypography>
      <MDBRow className="mb-4">
        <hr className="hr" />
        <MDBCol>
          <MDBTypography variant="h6" className="mb-3 fw-bold">
            PubPeer
          </MDBTypography>
          <MDBSwitch
            label="PubPeer hits"
            checked={values?.components?.FOCRat ?? false}
            onChange={({ target }) => onChange(target?.name, target?.checked)}
            name="components.FOCRat"
          />
          <br />
          <label className="grey-text">PubPeer API Key</label>
          <Select
            key={values?.service_keys?.pubPeerAPIKey}
            name="service_keys.pubPeerAPIKey"
            placeholder="Select key"
            options={keyOptions}
            value={keyOptions.find(
              (element) => element.value === values?.service_keys?.pubPeerAPIKey
            )}
            isDisabled={!values?.components?.FOCRat}
            onChange={({ value }, { name }) => onChange(name, value)}
          />
          {values?.components?.FOCRat === true &&
          !values?.service_keys?.pubPeerAPIKey ? (
            <div className="text-danger">Field is required</div>
          ) : null}
        </MDBCol>
        <MDBCol>
          <MDBTypography variant="h6" className="mb-3 fw-bold">
            ClearSkies Papermill Alarm
          </MDBTypography>
          <MDBSwitch
            label="Papermill similarity"
            checked={values?.components?.CSStat ?? false}
            onChange={({ target }) => onChange(target?.name, target?.checked)}
            name="components.CSStat"
          />
          <br />
          <label className="grey-text">ClearSkies API Key</label>
          <Select
            key={values?.service_keys?.clearSkiesAPIKey}
            name="service_keys.clearSkiesAPIKey"
            placeholder="Select key"
            options={keyOptions}
            value={keyOptions.find(
              (element) =>
                element.value === values?.service_keys?.clearSkiesAPIKey
            )}
            isDisabled={!values?.components?.CSStat}
            onChange={({ value }, { name }) => onChange(name, value)}
          />
          {values?.components?.CSStat === true &&
          !values?.service_keys?.clearSkiesAPIKey ? (
            <div className="text-danger">Field is required</div>
          ) : null}
        </MDBCol>
      </MDBRow>
      <MDBRow className="mb-4">
        <hr className="hr" />
        <MDBTypography variant="h6" className="mb-3 fw-bold">
          Cactus pilot
        </MDBTypography>
        <MDBCol>
          <MDBSwitch
            label="Cactus issues"
            checked={values?.components?.cactusTool ?? false}
            onChange={({ target }) => onChange(target?.name, target?.checked)}
            name="components.cactusTool"
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="mb-3">
        <hr className="hr" />
        <MDBCol>
          <MDBTypography variant="h6" className="mb-3 fw-bold">
            Reference analysis
          </MDBTypography>
          <MDBSwitch
            label="Retraction Watch"
            checked={values?.components?.RWtch ?? false}
            onChange={({ target }) => onChange(target?.name, target?.checked)}
            name="components.RWtch"
          />
          <br />
          <MDBSwitch
            label="DOI analysis"
            checked={values?.components?.CRRF ?? false}
            onChange={({ target }) => onChange(target?.name, target?.checked)}
            name="components.CRRF"
          />
        </MDBCol>
        <MDBCol className="mb-3">
          <MDBTypography variant="h6" className="mb-3 fw-bold">
            Text analysis
          </MDBTypography>
          <MDBSwitch
            label="Tortured phrases"
            checked={values?.components?.TPHrs ?? false}
            onChange={({ target }) => onChange(target?.name, target?.checked)}
            name="components.TPHrs"
          />
          <br />
          <MDBSwitch
            label="Unnatural text"
            checked={values?.components?.MLDet ?? false}
            onChange={({ target }) => onChange(target?.name, target?.checked)}
            name="components.MLDet"
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="mb-3">
        <hr className="hr" />
        <MDBCol>
          <MDBTypography variant="h6" className="mb-3 fw-bold">
            Metadata analysis
          </MDBTypography>
          <MDBSwitch
            label="Word file metadata"
            checked={values?.components?.wordFileProperties ?? false}
            onChange={({ target }) => onChange(target?.name, target?.checked)}
            name="components.wordFileProperties"
          />
        </MDBCol>
        <MDBCol>
          <MDBTypography variant="h6" className="mb-3 fw-bold">
            Duplicate submissions
          </MDBTypography>
          <MDBSwitch
            checked={values?.components?.OPScr ?? false}
            label="Metadata screening"
            onChange={({ target }) => onChange(target?.name, target?.checked)}
            name="components.OPScr"
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="mb-4">
        <hr className="hr" />
        <MDBCol>
          <MDBTypography variant="h6" className="mb-3 fw-bold">
            Watchlist
          </MDBTypography>
          <MDBSwitch
            label="Fake affiliation names"
            checked={values?.components?.SSPan ?? false}
            onChange={({ target }) => onChange(target?.name, target?.checked)}
            name="components.SSPan"
          />
          <br />
          <MDBSwitch
            label="Fake names or e-mail addresses"
            checked={values?.components?.SSPidd ?? false}
            onChange={({ target }) => onChange(target?.name, target?.checked)}
            name="components.SSPidd"
          />
          <br />
          <MDBSwitch
            label="Fake e-mail domains"
            checked={values?.components?.SSPfd ?? false}
            onChange={({ target }) => onChange(target?.name, target?.checked)}
            name="components.SSPfd"
          />
          <br />
          <MDBSwitch
            label="Disposable e-mail domains"
            checked={values?.components?.SSPdd ?? false}
            onChange={({ target }) => onChange(target?.name, target?.checked)}
            name="components.SSPdd"
          />
        </MDBCol>
        <MDBCol>
          <br />
          <MDBSwitch
            label="Bad actors"
            checked={values?.components?.SSPba ?? false}
            onChange={({ target }) => onChange(target?.name, target?.checked)}
            name="components.SSPba"
          />
          <br />
          <MDBSwitch
            label="Suspect articles"
            checked={values?.components?.SSPsa ?? false}
            onChange={({ target }) => onChange(target?.name, target?.checked)}
            name="components.SSPsa"
          />
          <br />
          <MDBSwitch
            label="Manuscripts offered for sale"
            checked={values?.components?.SSPmfs ?? false}
            onChange={({ target }) => onChange(target?.name, target?.checked)}
            name="components.SSPmfs"
          />
          <br />
          <MDBSwitch
            label="Metadata suspects"
            checked={values?.components?.SSMeta ?? false}
            onChange={({ target }) => onChange(target?.name, target?.checked)}
            name="components.SSMeta"
          />
          <br />
          <MDBSwitch
            label="GenAI response"
            checked={values?.components?.watchlistChatGPTOutput ?? false}
            onChange={({ target }) => onChange(target?.name, target?.checked)}
            name="components.watchlistChatGPTOutput"
          />
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default PublisherProfileForm;
