import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import PublisherProfileForm from '../../../publishers/components/PublisherProfileForm';
import {
  getProfile,
  showErrorToast,
  showSuccessToast,
  updateProfile,
} from '../../../../helpers';
import { FormModalWrapper } from '../../../../components';

const profileValidationSchema = Yup.object({
  profile_name: Yup.string().required('Profile name is required'),
});

const PublisherProfileEditModal = ({ itemId, onClose, onSuccessSubmit }) => {
  const isOpen = !!itemId;

  const [isLoading, setIsLoading] = useState(false);

  const handleCloseModal = () => {
    onClose();
    formik.resetForm();
  };

  const formik = useFormik({
    validationSchema: profileValidationSchema,
    initialValues: null,
    onSubmit: async () => {
      try {
        const profileToSubmit = {
          profile_name: formik.values.profile_name,
          components: formik.values.components,
          service_keys: formik.values.service_keys,
        };
        await updateProfile(formik.values.profile_id, profileToSubmit);
        showSuccessToast();
        await onSuccessSubmit();
        handleCloseModal();
      } catch (error) {
        showErrorToast();
      }
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await getProfile(itemId);
        formik.setValues(response.data);
      } catch (error) {
        showErrorToast();
      } finally {
        setIsLoading(false);
      }
    };

    if (!!itemId) {
      fetchData();
    }
  }, [itemId]);

  return (
    <FormModalWrapper
      title={'Edit profile'}
      isOpen={isOpen}
      isLoading={isLoading || formik.isSubmitting}
      onSubmit={formik.handleSubmit}
      onClose={handleCloseModal}
    >
      <PublisherProfileForm
        values={formik.values}
        onChange={formik.setFieldValue}
        errors={formik.errors}
      />
    </FormModalWrapper>
  );
};

export default PublisherProfileEditModal;
