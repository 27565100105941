import React, { useEffect, useState } from "react";
import { MDBBtn, MDBIcon, MDBTooltip } from "mdb-react-ui-kit";
import DataTable from "../../components/table/DataTable";
import { getPublisherProfilesMonitoring, showErrorToast } from "../../helpers";
import SpinnerOverlay from "../../components/SpinnerOverlay/SpinnerOverlay";
import { useNavigate } from "react-router-dom";
import { columns } from "./columns";
import { exportToExcel } from "./export";

const MonitorProfiles = () => {
  const [data, setData] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const getColumnLabelByKey = (key) => {
    const column = columns.find((col) => col.field === key);
    return column ? column.label : key;
  };

  const prepareComponents = (components, counts) => {
    const prepared = {};
    if (components) {
      for (const [key, value] of Object.entries(components)) {
        const tooltipTitle = getColumnLabelByKey(key);
        if (value) {
          counts[key] = (counts[key] || 0) + 1;
        }
        prepared[key] = value ? (
          <MDBTooltip tag="a" wrapperProps={{ href: "#" }} title={tooltipTitle}>
            <MDBIcon fas icon="check" className="text-success" />
          </MDBTooltip>
        ) : (
          <MDBTooltip tag="a" wrapperProps={{ href: "#" }} title={tooltipTitle}>
            <MDBIcon fas icon="times" className="text-danger" />
          </MDBTooltip>
        );
      }
    }
    return prepared;
  };

  const mapResponseData = (responseArray) => {
    const output = [];
    const counts = {};

    responseArray?.forEach((response) => {
      output.push({
        publisherName: (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              navigate(`/publisher-management/${response.id}`);
            }}
          >
            {response?.name}
          </a>
        ),
      });
      response?.profiles?.forEach((profile) => {
        const componentsWithIcons = prepareComponents(
          profile?.components,
          counts
        );
        output.push({
          profileName: (
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                navigate(`/publisher-management/${response.id}`);
              }}
            >
              {profile?.profileName}
            </a>
          ),
          ...componentsWithIcons,
        });
      });
    });

    const totalRow = { publisherName: "Count" };
    columns.forEach((column) => {
      if (column.field !== "publisherName" && column.field !== "profileName") {
        totalRow[column.field] = counts[column.field] || 0;
      }
    });

    output.push(totalRow);
    return output;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const { data } = await getPublisherProfilesMonitoring();
        setExportData(data);
        const mappedData = mapResponseData(data);
        setData(mappedData);
      } catch (error) {
        showErrorToast();
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleExport = () => {
    exportToExcel(exportData, "ProfilesMonitoring.xlsx");
  };

  return (
    <SpinnerOverlay active={isLoading}>
      <div className="d-flex justify-content-end mb-3">
        <MDBBtn
          disabled={isLoading}
          color="primary"
          className="px-4"
          onClick={handleExport}
        >
          Export
        </MDBBtn>
      </div>

      <DataTable
        columns={columns}
        rows={data}
        verticalHeaders
        isTableHeadSticky
      />
    </SpinnerOverlay>
  );
};

export default MonitorProfiles;
