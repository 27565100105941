export const token = `${process.env.REACT_APP_TOKEN}`;
export const apiBaseUrl = `${process.env.REACT_APP_API_BASE_URL}`;

export const itemsPerPage = 8;
export const errorMessage = "Error, something went wrong";
export const successMessage = "Action completed successfully"
export const linkedDeviceMessage =
  "You have successfully linked your TOTP device to your account. Please login with your new MFA flow";
export const notificationsTime = 3000;

export const regExpPassword =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[-#!$'�??%^@&*()_+|~=`{}\[\]'<>?,.\/]).{8,}$/;
export const regExpEmail =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i;
export const regExpDomain = /^[A-Z0-9._%+-]+@?[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

export const defaultColor = "#4285F4";

export const suspectTypeSelectOptions = [
  { value: 1, label: "Fake e-mail domains" },
  { value: 2, label: "Disposable e-mail domains" },
  { value: 3, label: "Fake affiliation names" },
  { value: 4, label: "Fake names or e-mail addresses" },
  { value: 5, label: "Bad actors" },
  { value: 6, label: "Suspect articles" },
  { value: 7, label: "Manuscripts offered for sale" },
  { value: 8, label: "Metadata suspects" },
  { value: 9, label: "GenAI response" }
];

export const PASSWORD_SETUP_OPTIONS = {
  KEEP_CURRENT: 1,
  SYSTEM_GENERATED: 2,
  CUSTOM: 3,
};

export const EDIT_USER_PASSWORD_SETUP_OPTIONS = [
  {
    value: PASSWORD_SETUP_OPTIONS.KEEP_CURRENT,
    label: 'Keep current password',
  },
  {
    value: PASSWORD_SETUP_OPTIONS.SYSTEM_GENERATED,
    label: 'Send a system generated password to the user',
  },
  {
    value: PASSWORD_SETUP_OPTIONS.CUSTOM,
    label: 'Assign a specific password to the user',
  },
];

export const CREATE_USER_PASSWORD_SETUP_OPTIONS = [
  {
    value: PASSWORD_SETUP_OPTIONS.SYSTEM_GENERATED,
    label: 'Send a system generated password to the user',
  },
  {
    value: PASSWORD_SETUP_OPTIONS.CUSTOM,
    label: 'Assign a specific password to the user',
  },
];