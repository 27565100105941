import React from "react";
import {
  MDBInput,
  MDBCol,
  MDBRow,
  MDBTypography,
  MDBSwitch,
  MDBCheckbox,
} from "mdb-react-ui-kit";
import Select from "react-select";
import useKeys from "../../keys/hooks/useKeys";
import { CREATE_USER_PASSWORD_SETUP_OPTIONS } from "../../../constants";

const PublisherReviewForm = ({ data }) => {
  const { keysData } = useKeys({ itemsPerPage: Number.MAX_SAFE_INTEGER });

  const keyOptions = keysData?.map((key) => ({
    value: key.id?.toString(),
    label: key.key_name,
  }));

  return (
    <div>
      <MDBTypography variant="h4" className="mb-4">
        Submission preview
      </MDBTypography>
      <MDBTypography variant="h5">Publisher</MDBTypography>
      <MDBRow className="mb-4">
        <MDBCol md={6}>
          <MDBInput
            disabled
            value={data?.publisherForm?.name ?? ""}
            label="Name"
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="mb-3">
        <hr className="hr" />
        <MDBCol md={6}>
          <MDBTypography variant="h5">Profile</MDBTypography>
          <MDBInput
            disabled
            value={data?.publisherProfileForm?.profile_name ?? ""}
            label="Name"
          />
        </MDBCol>
        <MDBCol>
          <MDBTypography variant="h5" className="mb-3">
            Applications
          </MDBTypography>
          <MDBSwitch
            checked={data?.publisherProfileForm.components?.PMChk ?? false}
            disabled
            label="On-Demand Screening"
          />
          <br />
          <MDBSwitch
            checked={data?.publisherProfileForm.components?.AMBScr ?? false}
            disabled
            label="Ambient Screening"
          />
          <br />
          <MDBSwitch
            checked={data?.publisherProfileForm.components?.WTLst ?? false}
            disabled
            label="Watchlist"
          />
        </MDBCol>
      </MDBRow>
      <MDBTypography variant="h5" className="mb-3">
        Tools & signals
      </MDBTypography>
      <MDBRow className="mb-4">
        <hr className="hr" />
        <MDBCol>
          <MDBTypography variant="h6" className="mb-3 fw-bold">
            PubPeer
          </MDBTypography>
          <MDBSwitch
            checked={data?.publisherProfileForm.components?.FOCRat ?? false}
            disabled
            label="PubPeer hits"
          />
          <br />
          <label className="grey-text">PubPeer API Key</label>
          <Select
            key={data?.publisherProfileForm?.service_keys?.pubPeerAPIKey}
            placeholder="Select key"
            options={keyOptions}
            value={keyOptions.find(
              (element) =>
                element?.value ===
                data?.publisherProfileForm?.service_keys?.pubPeerAPIKey?.toString()
            )}
            isDisabled
          />
        </MDBCol>
        <MDBCol>
          <MDBTypography variant="h6" className="mb-3 fw-bold">
            ClearSkies Papermill Alarm
          </MDBTypography>
          <MDBSwitch
            disabled
            label="Papermill similarity"
            checked={data?.publisherProfileForm?.components?.CSStat ?? false}
          />
          <br />
          <label className="grey-text">ClearSkies API Key</label>
          <Select
            key={data?.publisherProfileForm?.service_keys?.clearSkiesAPIKey}
            placeholder="Select key"
            options={keyOptions}
            value={keyOptions.find(
              (element) =>
                element?.value ===
                data?.publisherProfileForm?.service_keys?.clearSkiesAPIKey?.toString()
            )}
            isDisabled
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="mb-3">
        <hr className="hr" />
        <MDBCol>
          <MDBTypography variant="h6" className="mb-3 fw-bold">
            Reference analysis
          </MDBTypography>
          <MDBSwitch
            disabled
            label="Retraction Watch"
            checked={data?.publisherProfileForm?.components?.RWtch ?? false}
          />
          <br />
          <MDBSwitch
            disabled
            label="DOI analysis"
            checked={data?.publisherProfileForm?.components?.CRRF ?? false}
          />
        </MDBCol>
        <MDBCol className="mb-3">
          <MDBTypography variant="h6" className="mb-3 fw-bold">
            Text analysis
          </MDBTypography>
          <MDBSwitch
            disabled
            label="Tortured phrases"
            checked={data?.publisherProfileForm?.components?.TPHrs ?? false}
          />
          <br />
          <MDBSwitch
            disabled
            label="Unnatural text"
            checked={data?.publisherProfileForm?.components?.MLDet ?? false}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="mb-4">
        <hr className="hr" />
        <MDBTypography variant="h6" className="mb-3 fw-bold">
          Cactus pilot
        </MDBTypography>
        <MDBCol>
          <MDBSwitch
            disabled
            label="Cactus issues"
            checked={
              data?.publisherProfileForm?.components?.cactusTool ?? false
            }
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="mb-3">
        <hr className="hr" />
        <MDBCol>
          <MDBTypography variant="h6" className="mb-3 fw-bold">
            Metadata analysis
          </MDBTypography>
          <MDBSwitch
            disabled
            checked={
              data?.publisherProfileForm?.components?.wordFileProperties ??
              false
            }
            label="Word file metadata"
          />
        </MDBCol>
        <MDBCol>
          <MDBTypography variant="h6" className="mb-3 fw-bold">
            Duplicate submissions
          </MDBTypography>
          <MDBSwitch
            disabled
            checked={data?.publisherProfileForm?.components?.OPScr ?? false}
            label="Metadata screening"
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="mb-4">
        <hr className="hr" />
        <MDBCol>
          <MDBTypography variant="h6" className="mb-3 fw-bold">
            Watchlist
          </MDBTypography>
          <MDBSwitch
            disabled
            label="Fake affiliation names"
            checked={data?.publisherProfileForm.components?.SSPan ?? false}
          />
          <br />
          <MDBSwitch
            disabled
            label="Fake names or e-mail addresses"
            checked={data?.publisherProfileForm.components?.SSPidd ?? false}
          />
          <br />
          <MDBSwitch
            disabled
            label="Fake e-mail domains"
            checked={data?.publisherProfileForm.components?.SSPfd ?? false}
          />
          <br />
          <MDBSwitch
            disabled
            label="Disposable e-mail domains"
            checked={data?.publisherProfileForm?.components?.SSPdd ?? false}
          />
        </MDBCol>
        <MDBCol>
          <br />
          <MDBSwitch
            disabled
            label="Bad actors"
            checked={data?.publisherProfileForm?.components?.SSPba ?? false}
          />
          <br />
          <MDBSwitch
            disabled
            label="Suspect articles"
            checked={data?.publisherProfileForm?.components?.SSPsa ?? false}
          />
          <br />
          <MDBSwitch
            disabled
            label="Manuscripts offered for sale"
            checked={data?.publisherProfileForm?.components?.SSPmfs ?? false}
          />
          <br />
          <MDBSwitch
            disabled
            label="Metadata suspects"
            checked={data?.publisherProfileForm?.components?.SSMeta ?? false}
          />
          <br />
          <MDBSwitch
            disabled
            label="GenAI response"
            checked={
              data?.publisherProfileForm?.components?.ChatGPTOutput ?? false
            }
          />
        </MDBCol>
      </MDBRow>
      <hr className="hr" />
      <MDBTypography variant="h5">User</MDBTypography>
      <MDBRow className="mb-4">
        <MDBCol md={6}>
          <MDBInput
            disabled
            value={data?.publisherUserForm?.email ?? ""}
            label="Email"
          />
        </MDBCol>
        <MDBCol md={6}>
          <Select
            key={data?.publisherUserForm.passwordSetupType}
            options={CREATE_USER_PASSWORD_SETUP_OPTIONS}
            value={CREATE_USER_PASSWORD_SETUP_OPTIONS.find(
              (element) =>
                element?.value === data?.publisherUserForm?.passwordSetupType
            )}
            isDisabled
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="mb-4">
        <MDBCol md={6}>
          <MDBCheckbox
            disabled
            label="Is Publisher Admin"
            checked={data?.publisherUserForm?.publisherSupervisoryRole}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol md={6}>
          <MDBCheckbox
            disabled
            label="Is Admin"
            checked={data?.publisherUserForm.isAdminGroup}
          />
        </MDBCol>
      </MDBRow>
    </div>
  );
};

export default PublisherReviewForm;
