import React, { useEffect, useState } from "react";
import {
  MDBCol,
  MDBInput,
  MDBRow,
} from "mdb-react-ui-kit";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { FormModalWrapper } from "../../../components";
import { showErrorToast, getKey } from "../../../helpers";
import useKeys from "../hooks/useKeys";

const keyValidationSchema = Yup.object({
  key_name: Yup.string().required('Key name is required'),
  key_path: Yup.string().required('Key path is required')
})

const KeyEditModal = ({ onClose, itemId, onSuccessSubmit }) => {
  const isOpen = !!itemId;

  const [isLoading, setIsLoading] = useState(false);

  const { handleEditKey } = useKeys({ doInitialFetch: false })

  const handleCloseModal = () => {
    onClose();
    formik.resetForm();
  }

  const formik = useFormik({
    validationSchema: keyValidationSchema,
    initialValues: null,
    onSubmit: async (values) => {
      try {
        await handleEditKey(itemId, values);
        handleCloseModal();
        await onSuccessSubmit();
      } catch (error) { }
    }
  });

  useEffect(() => {
    const fetchKey = async () => {
      try {
        setIsLoading(true)
        const response = await getKey(itemId);
        formik.setValues({
          key_name: response.data.key_name,
          key_path: response.data.key_path
        });
      } catch (error) {
        showErrorToast();
        handleCloseModal();
      }
      finally {
        setIsLoading(false)
      }
    };

    if (!!itemId) {
      fetchKey();
    }
  }, [itemId]);


  return (
    <FormModalWrapper
      title={'Edit key'}
      isOpen={isOpen}
      isLoading={isLoading || formik.isSubmitting}
      onSubmit={formik.handleSubmit}
      onClose={handleCloseModal}
    >
      <MDBRow className="mb-3">
        <MDBCol md={6}>
          <MDBInput
            id="key_name"
            label="Key name"
            {...formik.getFieldProps('key_name')}
          />
          {formik.errors.key_name ? (
            <div className="text-danger">{formik.errors.key_name}</div>
          ) : null}
        </MDBCol>
        <MDBCol md={6}>
          <MDBInput
            id="key_path"
            label="Key path"
            {...formik.getFieldProps('key_path')}
          />
          {formik.errors.key_path ? (
            <div className="text-danger">{formik.errors.key_path}</div>
          ) : null}
        </MDBCol>
      </MDBRow>
    </FormModalWrapper>
  );
};

export default KeyEditModal;
