import React, { useState, useEffect } from "react";
import {
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBTypography,
  MDBIcon,
} from "mdb-react-ui-kit";
import "./styles.css";

const DataTable = ({
  columns = [],
  rows = [],
  areRowsPreMapped = false,
  title = null,
  enableSorting = false,
  defaultSortField = null,
  defaultSortDirection = "asc",
  verticalHeaders = false,
}) => {
  const initialSortKey =
    defaultSortField || (columns.length > 0 ? columns[0].field : null);
  const initialSortDirection = defaultSortDirection;
  const [sortedRows, setSortedRows] = useState(rows);
  const [sortConfig, setSortConfig] = useState({
    key: initialSortKey,
    direction: initialSortDirection,
  });

  useEffect(() => {
    if (enableSorting && initialSortKey && !verticalHeaders) {
      sortRows(initialSortKey, initialSortDirection);
    } else {
      setSortedRows(rows);
    }
  }, [
    initialSortKey,
    rows,
    enableSorting,
    initialSortDirection,
    verticalHeaders,
  ]);

  const sortRows = (columnKey, direction) => {
    const sorted = [...rows].sort((a, b) => {
      if (columnKey?.toLowerCase?.().includes("timestamp")) {
        const dateA = new Date(a[columnKey]);
        const dateB = new Date(b[columnKey]);
        if (dateA < dateB) {
          return direction === "asc" ? -1 : 1;
        }
        if (dateA > dateB) {
          return direction === "asc" ? 1 : -1;
        }
        return 0;
      } else {
        const valueA =
          typeof a[columnKey] === "string"
            ? a[columnKey].toLowerCase()
            : a[columnKey];
        const valueB =
          typeof b[columnKey] === "string"
            ? b[columnKey].toLowerCase()
            : b[columnKey];

        if (valueA < valueB) {
          return direction === "asc" ? -1 : 1;
        }
        if (valueA > valueB) {
          return direction === "asc" ? 1 : -1;
        }
        return 0;
      }
    });

    setSortedRows(sorted);
    setSortConfig({ key: columnKey, direction });
  };

  const onSort = (columnKey) => {
    if (!enableSorting) return;
    let direction = "asc";
    if (sortConfig.key === columnKey && sortConfig.direction === "asc") {
      direction = "desc";
    }
    sortRows(columnKey, direction);
  };

  return (
    <>
      {title && (
        <MDBTypography tag={"h3"} className="mb-5 text-center text-muted">
          {title}
        </MDBTypography>
      )}
      <MDBTable
        responsive
        className="px-2"
        style={{ cursor: enableSorting ? "pointer" : "default" }}
        hover
      >
        <MDBTableHead light>
          <tr>
            {columns?.map((column, idx) => (
              <th
                key={idx}
                style={{
                  maxWidth: column?.width,
                  cursor:
                    enableSorting && !verticalHeaders ? "pointer" : "default",
                }}
                onClick={() => !verticalHeaders && onSort(column.field)}
              >
                <div className={verticalHeaders ? "vertical-header" : ""}>
                  {column.label}
                  {!verticalHeaders &&
                    enableSorting &&
                    (sortConfig.key === column.field ? (
                      sortConfig.direction === "asc" ? (
                        <MDBIcon className="ms-2" fas icon="sort-up" />
                      ) : (
                        <MDBIcon className="ms-2" fas icon="sort-down" />
                      )
                    ) : (
                      <MDBIcon className="ms-2" fas icon="sort" />
                    ))}
                </div>
              </th>
            ))}
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {areRowsPreMapped
            ? sortedRows
            : sortedRows.map((row, idx) => (
                <tr key={idx} onClick={row?.clickEvent} className="row-hover">
                  {columns?.map((column, idx) => (
                    <td
                      key={idx}
                      style={{
                        backgroundColor: column.backgroundColor || "inherit",
                        maxWidth: column?.width,
                      }}
                    >
                      {column.format
                        ? column.format(row[column.field])
                        : row[column.field]}
                    </td>
                  ))}
                </tr>
              ))}
        </MDBTableBody>
      </MDBTable>
    </>
  );
};

export default DataTable;
