import React, { useEffect, useState } from "react";
import {
  MDBCheckbox,
  MDBCol,
  MDBInput,
  MDBRow,
} from "mdb-react-ui-kit";
import * as Yup from 'yup';
import Select from "react-select";
import { useFormik } from "formik";
import { FormModalWrapper } from "../../../components";
import { showErrorToast, getUser, updateUser, getProfilesByPublisher, showSuccessToast } from "../../../helpers";
import { EDIT_USER_PASSWORD_SETUP_OPTIONS, PASSWORD_SETUP_OPTIONS, regExpPassword } from "../../../constants";

const UserEditModal = ({ onClose, itemId, onSuccessSubmit }) => {
  const isOpen = !!itemId;

  const [profileOptions, setProfileOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleCloseModal = () => {
    onClose();
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: null,
    validationSchema: Yup.object().shape({
      email: Yup.string().email('Invalid email').required('Email is required'),
      profile_id: Yup.number().required('Profile is required'),
      passwordSetupType: Yup.number().required(),
      customPassword: Yup.string()
        .when('passwordSetupType', {
          is: PASSWORD_SETUP_OPTIONS.CUSTOM,
          then: schema => schema
            .matches(regExpPassword, 'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character, and be at least 8 characters long')
            .required('Password is required'),
          otherwise: schema => schema.notRequired(),
        }),
    }),
    onSubmit: async (values) => {
      try {
        const userToSubmit = {
          email: values?.email?.toLowerCase(),
          profile_id: values.profile_id,
          is_admin_group: values.is_admin_group,
          permissions: {
            publisherSupervisoryRole: values?.publisherSupervisoryRole
          },
          passwordSetupType: values?.passwordSetupType
        }

        if (userToSubmit?.passwordSetupType === PASSWORD_SETUP_OPTIONS.CUSTOM) {
          userToSubmit.password = values?.customPassword
        }
        await updateUser(values.id, userToSubmit);
        showSuccessToast();
        await onSuccessSubmit();
        handleCloseModal();
      } catch (error) {
        showErrorToast();
      }
    },
  });

  useEffect(() => {
    const fetchUser = async () => {
      setIsLoading(true)
      try {
        const response = await getUser(itemId);

        const user = response.data;
        formik.setValues({
          id: user.id,
          username: user.username,
          profile_id: user.profileId,
          email: user.email,
          is_admin_group: user.administratorsGroup,
          publisherSupervisoryRole: user?.permissions?.publisherSupervisoryRole ?? false,
          passwordSetupType: PASSWORD_SETUP_OPTIONS.KEEP_CURRENT
        })
        if (response.data.publisher_id) {
          const profilesListResponse = await getProfilesByPublisher(response.data.publisher_id)
          const remapedProfileOptions = profilesListResponse.data.rows.map(x => ({
            label: x.profile_name,
            value: x.profile_id
          }));
          setProfileOptions(remapedProfileOptions);
        }
      } catch (error) {
        showErrorToast();
        handleCloseModal()
      }
      finally {
        setIsLoading(false)
      }
    };

    if (!!itemId) {
      fetchUser();
    }
  }, [itemId]);

  const handleProfileChange = (e) => {
    formik.setFieldValue('profile_id', e.value);
  };

  const handlePasswordSetupTypeChange = (e) => {
    if (e.value !== PASSWORD_SETUP_OPTIONS.CUSTOM) {
      formik.setFieldValue("customPassword")
    }
    formik.setFieldValue('passwordSetupType', e.value);
  };

  const showCustomPasswordField = formik.values?.passwordSetupType === PASSWORD_SETUP_OPTIONS.CUSTOM

  return (
    <FormModalWrapper
      title={'Edit user'}
      isOpen={isOpen}
      isLoading={isLoading || formik.isSubmitting}
      onSubmit={formik.handleSubmit}
      onClose={handleCloseModal}
    >
      <MDBRow className="mb-3">
        <MDBCol md={6} style={{ marginTop: '27px' }}>
          <MDBInput label="Username" disabled value={formik.values?.username} />
        </MDBCol>
      </MDBRow>
      <MDBRow className="mb-3">
        <MDBCol md={6} style={{ marginTop: '27px' }}>
          <MDBInput label="Email" id="email" type="email" {...formik.getFieldProps('email')} />
        </MDBCol>
        <MDBCol md={6}>
          <label className="grey-text">Profile</label>
          <Select
            {...formik.getFieldProps('profile_id')}
            id="profile_id"
            placeholder='Select profile'
            options={profileOptions}
            value={profileOptions.find(element => element.value === formik.values?.profile_id)}
            onChange={handleProfileChange}
            onBlur={() => formik.setFieldTouched('profile_id')}
            isSearchable={true}
          />
          {formik.errors.profile_id ? (
            <div className="text-danger">{formik.errors.profile_id}</div>
          ) : null}
        </MDBCol>
      </MDBRow>
      <MDBRow className='mt-3'>
        <MDBCol>
          <MDBCheckbox
            id='is_admin_group'
            name='is_admin_group'
            label='Is Admin'
            checked={formik.values?.is_admin_group}
            onChange={() => formik.setFieldValue('is_admin_group', !formik.values.is_admin_group)}
            onBlur={formik.handleBlur}
          />
        </MDBCol>
        <MDBCol>
          <Select
            {...formik.getFieldProps('passwordSetupType')}
            id="passwordSetupType"
            placeholder='Select password setup'
            options={EDIT_USER_PASSWORD_SETUP_OPTIONS}
            value={EDIT_USER_PASSWORD_SETUP_OPTIONS.find(element => element.value === formik.values?.passwordSetupType)}
            onChange={handlePasswordSetupTypeChange}
            onBlur={() => formik.setFieldTouched('passwordSetupType')}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className='mt-3'>
        <MDBCol>
          <MDBCheckbox
            id='publisherSupervisoryRole'
            name='publisherSupervisoryRole'
            label='Is Publisher Admin'
            checked={formik.values?.publisherSupervisoryRole}
            onChange={() => formik.setFieldValue('publisherSupervisoryRole', !formik.values.publisherSupervisoryRole)}
            onBlur={formik.handleBlur}
          />
        </MDBCol>
        <MDBCol>
          {showCustomPasswordField && (
            <>
              <MDBInput
                label="Password"
                id="customPassword"
                type="password"
                {...formik?.getFieldProps('customPassword')}
                autoComplete="new-password"
              />
              {formik?.errors?.customPassword ? (
                <div className="text-danger">{formik?.errors?.customPassword}</div>
              ) : null}
            </>
          )}
        </MDBCol>
      </MDBRow>
    </FormModalWrapper >
  );
};

export default UserEditModal;
