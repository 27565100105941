import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { FormModalWrapper } from '../../../../components';
import PublisherProfileForm from '../../../publishers/components/PublisherProfileForm';
import {
  createProfile,
  showErrorToast,
  showSuccessToast,
} from '../../../../helpers';

const PublisherProfileCreateModal = ({ isOpen, onClose, onSuccessSubmit }) => {
  let { id: publisherId } = useParams();

  const handleCloseModal = () => {
    onClose();
    formik.resetForm();
  };
  const profileValidationSchema = Yup.object({
    profile_name: Yup.string().required('Profile name is required'),
  });

  const formik = useFormik({
    validationSchema: profileValidationSchema,
    initialValues: {
      profile_name: '',
      components: {
        CRRF: false,
        MLDet: false,
        OPScr: false,
        PMChk: false,
        SSPan: false,
        SSPdd: false,
        SSPfd: false,
        TPHrs: false,
        WTLst: false,
        CSStat: false,
        FOCRat: false,
        AMBScr: false,
      },
      service_keys: {
        clearSkiesAPIKey: '',
        pubPeerAPIKey: '',
      },
    },
    onSubmit: async (values) => {
      try {
        const newProfile = {
          ...values,
          publisher_id: publisherId,
        };
        await createProfile(newProfile);
        await onSuccessSubmit(formik.values);
        showSuccessToast();
        handleCloseModal();
      } catch (error) {
        showErrorToast();
      }
    },
  });

  return (
    <FormModalWrapper
      title={'Create profile'}
      isOpen={isOpen}
      isLoading={formik.isSubmitting}
      onSubmit={formik.handleSubmit}
      onClose={handleCloseModal}
    >
      <PublisherProfileForm
        values={formik.values}
        onChange={formik.setFieldValue}
        errors={formik.errors}
      />
    </FormModalWrapper>
  );
};

export default PublisherProfileCreateModal;
