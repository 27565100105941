import React, { useEffect, useState } from 'react';
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBModalDialog,
  MDBModalContent,
  MDBModalTitle,
} from 'mdb-react-ui-kit';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import PublisherForm from './PublisherForm';
import PublisherReviewForm from './PublisherReviewForm';
import PublisherProfileForm from './PublisherProfileForm';
import PublisherUserForm from './PublisherUserForm';
import {
  createPublisher,
  showSuccessToast,
  showErrorToast,
} from '../../../helpers';
import { PASSWORD_SETUP_OPTIONS, regExpPassword } from '../../../constants';

const steps = {
  publisherForm: 'publisherForm',
  publisherProfileForm: 'publisherProfileForm',
  publisherUserForm: 'publisherUserForm',
  publisherReviewForm: 'publisherReviewForm',
};

const initialFormValues = {
  publisherForm: {
    name: '',
  },
  publisherProfileForm: {
    profile_name: '',
    components: {
      CRRF: false,
      MLDet: false,
      OPScr: false,
      PMChk: false,
      SSPan: false,
      SSPdd: false,
      SSPfd: false,
      TPHrs: false,
      WTLst: false,
      CSStat: false,
      FOCRat: false,
      AMBScr: false,
    },
    service_keys: {
      pubPeerAPIKey: '',
      clearSkiesAPIKey: '',
    },
  },
  publisherUserForm: {
    email: '',
    isAdminGroup: false,
    publisherSupervisoryRole: false,
    passwordSetupType: PASSWORD_SETUP_OPTIONS.SYSTEM_GENERATED
  },
};

const publisherFormSchema = Yup.object({
  publisherForm: Yup.object({
    name: Yup.string().required('Publisher name is required'),
  }),
});

const publisherProfileFormSchema = Yup.object({
  publisherProfileForm: Yup.object({
    profile_name: Yup.string().required('Profile name is required'),
  }),
});

const publisherUserFormSchema = Yup.object({
  publisherUserForm: Yup.object({
    email: Yup.string().required('Email is required').email('Invalid email'),
    passwordSetupType: Yup.number().required(),
    customPassword: Yup.string()
      .when('passwordSetupType', {
        is: PASSWORD_SETUP_OPTIONS.CUSTOM,
        then: schema => schema
          .matches(regExpPassword, 'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character, and be at least 8 characters long')
          .required('Password is required'),
        otherwise: schema => schema.notRequired(),
      }),
  }),
});

const combinedSchema = Yup.object({
  ...publisherFormSchema.fields,
  ...publisherProfileFormSchema.fields,
  ...publisherUserFormSchema.fields,
});

const CreatePublisherModal = ({ isOpen, setIsOpen, onSuccessfulSubmit }) => {
  const [step, setStep] = useState(steps.publisherForm);

  const formik = useFormik({
    validationSchema: combinedSchema,
    initialValues: initialFormValues,
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleReset = () => {
      setStep(steps.publisherForm);
    };

    handleReset();
  }, [isOpen]);

  const toggleModal = () => {
    setIsOpen(!isOpen);
    formik.resetForm();
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const requestBody = {
        publisher: { ...formik.values.publisherForm },
        profile: { ...formik.values.publisherProfileForm },
        user: {
          email: formik.values.publisherUserForm?.email?.toLowerCase(),
          is_admin_group: formik.values.publisherUserForm?.isAdminGroup,
          permissions: {
            publisherSupervisoryRole:
              formik.values.publisherUserForm?.publisherSupervisoryRole,
          },
          passwordSetupType: formik.values.publisherUserForm.passwordSetupType
        },
      };
      if (formik.values.publisherUserForm.passwordSetupType === PASSWORD_SETUP_OPTIONS.CUSTOM) {
        requestBody.user.password =
          formik.values.publisherUserForm.customPassword;
      }
      await createPublisher(requestBody);
      showSuccessToast();
      setIsOpen(false);
      formik.resetForm();
      await onSuccessfulSubmit();
    } catch (error) {
      showErrorToast();
    } finally {
      setLoading(false);
    }
  };

  const handleNext = () => {
    formik.validateForm().then((errors) => {
      const currentStepErrors = Object.keys(errors).filter((key) =>
        key.startsWith(step)
      );
      if (currentStepErrors.length === 0) {
        switch (step) {
          case steps.publisherForm:
            setStep(steps.publisherProfileForm);
            break;
          case steps.publisherProfileForm:
            setStep(steps.publisherUserForm);
            break;
          case steps.publisherUserForm:
            setStep(steps.publisherReviewForm);
            break;
          default:
            break;
        }
      } else {
        const touchedFields = currentStepErrors.reduce(
          (acc, key) => ({ ...acc, [key]: true }),
          {}
        );
        formik.setTouched(touchedFields);
      }
    });
  };
  const handleBack = () => {
    switch (step) {
      case steps.publisherProfileForm:
        setStep(steps.publisherForm);
        break;
      case steps.publisherUserForm:
        setStep(steps.publisherProfileForm);
        break;
      case steps.publisherReviewForm:
        setStep(steps.publisherUserForm);
        break;
      default:
        break;
    }
  };
  const handlePublisherFormChange = (event) => {
    const { name, value } = event.target;
    formik.setFieldValue(`publisherForm.${name}`, value);
  };

  const handlePublisherProfileFormChange = (name, value) => {
    formik.setFieldValue(`publisherProfileForm.${name}`, value);
  };

  const handlePublisherUserFormChange = (name, value) => {
    formik.setFieldValue(`publisherUserForm.${name}`, value);
  };

  const getUserFormFieldProps = (field) =>
    formik.getFieldProps(`publisherUserForm.${field}`);

  const renderForm = () => {
    switch (step) {
      case steps.publisherForm:
        return (
          <PublisherForm
            values={formik.values.publisherForm}
            onChange={handlePublisherFormChange}
            errors={formik.errors.publisherForm}
          />
        );
      case steps.publisherProfileForm:
        return (
          <PublisherProfileForm
            values={formik.values.publisherProfileForm}
            onChange={handlePublisherProfileFormChange}
            errors={formik.errors.publisherProfileForm}
          />
        );
      case steps.publisherUserForm:
        return (
          <PublisherUserForm
            values={formik.values.publisherUserForm}
            onChange={handlePublisherUserFormChange}
            errors={formik.errors.publisherUserForm}
            getFieldProps={getUserFormFieldProps}
            touched={formik.touched.publisherUserForm}
          />
        );
      case steps.publisherReviewForm:
        return <PublisherReviewForm data={formik.values} />;
      default:
        return null;
    }
  };

  return (
    <MDBModal staticBackdrop show={isOpen} setShow={setIsOpen} tabIndex="-1">
      <MDBModalDialog className="modal-xl">
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>{'Create publisher'}</MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={toggleModal}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody className="px-4">{renderForm()}</MDBModalBody>
          <MDBModalFooter className="justify-content-center">
            {step !== steps.publisherForm && (
              <MDBBtn color="secondary" disabled={loading} onClick={handleBack}>
                Back
              </MDBBtn>
            )}
            {step !== steps.publisherReviewForm ? (
              <MDBBtn
                color="primary"
                type="submit"
                disabled={loading}
                onClick={handleNext}
              >
                Next
              </MDBBtn>
            ) : (
              <MDBBtn color="success" disabled={loading} onClick={handleSubmit}>
                Submit
              </MDBBtn>
            )}
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default CreatePublisherModal;
