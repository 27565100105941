import { toast } from "react-toastify";
import {
  notificationsTime,
  errorMessage as defaultErrorMessage,
  successMessage as defaultSuccessMessage,
  token as TOKEN,
} from "../constants";
import dayjs from "dayjs";

export const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export const confirmAdminToken = (auth = null) => {
  const decoded = parseJwt(auth ? auth : localStorage.getItem(TOKEN));
  return decoded && decoded["cognito:groups"]?.includes("Administrators");
};

export const truncateDecimals = function (number, digits) {
  var multiplier = Math.pow(10, digits),
    adjustedNum = number * multiplier,
    truncatedNum = Math[adjustedNum < 0 ? "ceil" : "floor"](adjustedNum);
  return truncatedNum / multiplier;
};

export const isObject = (variable) =>
  variable !== null && typeof variable === "object" && !Array.isArray(variable);

export const isEmptyObject = (o) => Object.keys(o).length === 0;

export const sortObject = (o) =>
  Object.keys(o)
    .sort()
    .reduce((r, k) => ((r[k] = o[k]), r), {});

export const showSuccessToast = (successMessage = defaultSuccessMessage) => {
  return toast.success(successMessage, {
    position: "top-right",
    autoClose: notificationsTime,
  });
};

export const showErrorToast = (error) => {
  let errorMessage = error || defaultErrorMessage;

  if (error instanceof Object) {
    const responseData = error?.response?.data;
    errorMessage =
      responseData?.errorMessage?.message ||
      responseData?.errorMessage ||
      error.message;
  }

  return toast.error(errorMessage, {
    position: "top-right",
    autoClose: notificationsTime,
  });
};

export const filterObj = (obj, filterKey) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([key, value]) => key !== filterKey)
  );
};

export const convertISOToReadableDate = (
  isoDate,
  format = "YYYY-MM-DD HH:mm"
) => {
  const date = dayjs(isoDate);
  return date.isValid() ? date.format(format) : "";
};

export const truncateString = (text = "", maxLength = 50) => {
  return text.length > maxLength
    ? text.substring(0, maxLength - 3) + "..."
    : text;
};
